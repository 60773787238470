import React from 'react';
import { Box } from '@chakra-ui/react';

const MasterLayout = ({children, noPadding, sidebarWidth}) => {
  return (
    <Box p={noPadding ? 0 : 5} ml={sidebarWidth}>
      {children}
    </Box>
  )
}

export default MasterLayout;
