import { FETCH_SUBREDDITS, UPDATE_ACTIVE_USERS_GRID, SET_TIMEFRAME, SET_CHART_DATA, SET_SUBREDDIT, TOGGLE_SUBREDDIT_MANAGER, UPDATE_REALTIME_CHART_DATA, TOGGLE_CHART_TYPE, UPDATE_ACTIVE_USER_COUNT } from '../actions/types';

const initialState = {
  subreddit: '',
  timeframe: "1m",
  chartData: [],
  showSubredditManager: true,
  subredditId: null,
  subredditName: '',
  chartTypes: {
    "1m": true,
    "5m": true,
    "15m": true,
    "30m": true,
    "1H": true,
    "4H": true,
    "1D": true
  },
  activeUserCounts: {},
  subreddits: [],
  activeUserCounts: {},
};

export default function(state = initialState, action) {
  switch(action.type) {
    case SET_SUBREDDIT:
      return {
        ...state,
        subredditId: action.payload.subredditId,
        subredditName: action.payload.subredditName    
      };
    case TOGGLE_SUBREDDIT_MANAGER:
      return {
        ...state,
        showSubredditManager: !state.showSubredditManager
      };  
    case SET_TIMEFRAME:
      return {
        ...state,
        timeframe: action.payload
      };
    case UPDATE_REALTIME_CHART_DATA:
      const newData = action.payload;
      const updatedChartData = [...state.chartData, newData].sort((a, b) => a.time - b.time);
      return {
          ...state,
          chartData: updatedChartData
      };
    case SET_CHART_DATA:
      return {
        ...state,
        chartData: action.payload
      };
    case FETCH_SUBREDDITS:
      return {
        ...state,
        subreddits: action.payload
      };
    case UPDATE_ACTIVE_USERS_GRID:
      return {
        ...state,
        activeUserCounts: action.payload
      };
    case TOGGLE_CHART_TYPE:
      return {
        ...state,
        chartTypes: {
          ...state.chartTypes,
          [action.payload]: !state.chartTypes[action.payload]
        }
      };
    case UPDATE_ACTIVE_USER_COUNT:
      return {
        ...state,
        activeUserCounts: {
          ...state.activeUserCounts,
          [action.payload.subredditId]: action.payload.count
        }
      };
    default:
      return state;
  }
}
