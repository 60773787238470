// actions/appmenu.js
import {
  TOGGLE_SIDEBAR,
  SET_SIDEBAR_STATE,
  SET_USER_TOGGLED,
  UI_LOADED,
} from '../actions/types';

const initialState = {
  sidebarOpen: JSON.parse(localStorage.getItem('sidebarState')) || true,
  userToggled: false,
};


const appmenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      const newState = !state.sidebarOpen;
      localStorage.setItem('sidebarState', JSON.stringify(newState));
      return {
        ...state,
        sidebarOpen: newState,
      };
    case SET_SIDEBAR_STATE:
      localStorage.setItem('sidebarState', JSON.stringify(action.payload));
      return {
        ...state,
        sidebarOpen: action.payload,
      };
    case SET_USER_TOGGLED:
      return {
        ...state,
        userToggled: action.payload,
      };
    case UI_LOADED:
      return {
        ...state,
        uiLoaded: true,
      };
    default:
      return state;
  }
};

export default appmenuReducer;
