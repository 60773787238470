import { combineReducers } from 'redux';
import posts from './posts';
import platform from './platform';
import user from './user';
import ytts from './ytts';
import appmenu from './appmenu';
import gmaps from './gmaps';
import rau from './rau';
import raugrid from './raugrid';
import scriptsync from './scriptsync';
import announcements from './announcements';
import admin from './admin';

const rootReducer = combineReducers({
  posts,
  platform,
  user,
  ytts,
  appmenu,
  gmaps,
  rau,
  raugrid,
  scriptsync,
  announcements,
  admin,
});

export default rootReducer;
