import { FETCH_CURRENT_USER, LOGOUT_USER } from './types';

export const fetchCurrentUser = () => async dispatch => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const response = await fetch(`${API_BASE_URL}/current-user`, { credentials: 'include' });
  const data = await response.json();

  dispatch({ 
    type: FETCH_CURRENT_USER, 
    payload: { 
      username: data.username, 
      id: data.id, 
      roles: data.roles,
      permissions: data.permissions
    }
  });
};

export const logoutUser = () => dispatch => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${API_BASE_URL}/logout`, {
    method: 'POST',
    credentials: 'include'
  })
  .then(() => {
    dispatch({ type: LOGOUT_USER });
  });
};
