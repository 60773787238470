import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const WebSocketHandler = ({ socket, currentUser }) => {
    const location = useLocation();

    useEffect(() => {
        // Check if the socket is connected and if the currentUser is defined
        if (socket && socket.connected && currentUser) {
            socket.emit('message', JSON.stringify({ type: 'INIT', username: currentUser, currentPage: location.pathname }));

            // Cleanup when the component is unmounted or the location changes
            return () => {
                if (socket.connected) {
                    socket.emit('message', JSON.stringify({ type: 'LEAVE_PAGE', username: currentUser, currentPage: location.pathname }));
                }
            };
        }
    }, [location, socket, currentUser]);

    useEffect(() => {
        return () => {};
    }, []);

    return null; // This component doesn't render anything to the DOM.
};

export default WebSocketHandler;
