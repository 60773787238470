import React from 'react';
import { useColorMode } from '@chakra-ui/react';
import './LoginBackground.css';

const LoginBackground = () => {
  const { colorMode } = useColorMode();

  const renderSVG = (mode) => {
    if (mode === 'dark') {
      return (
        <svg className="background--custom-dark" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path fill="#74877B" fill-opacity="0.2" d="M-100 -100L200 -100L200 20L-100 20Z" style={{ animation: 'path0-dark 4.237288135593221s linear infinite alternate' }} />
          <path fill="#601FBC" fill-opacity="0.3" d="M-100 -100L200 -100L200 30L-100 30Z" style={{ animation: 'path1-dark 11.904761904761905s linear infinite alternate' }} />
          <path fill="#04054c" fill-opacity="0.1" d="M-100 -100L200 -100L200 20L-100 20Z" style={{ animation: 'path2-dark 3.4722222222222223s linear infinite alternate' }} />
        </svg>
      );
    } else {
      return (
        <svg className="background--custom-light" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path fill="#3F3484" fill-opacity="0.9" d="M-100 -100L200 -100L200 20L-100 20Z" style={{ animation: 'path0-light 4.237288135593221s linear infinite alternate' }} />
          <path fill="#426BDA" fill-opacity="0.2" d="M-100 -100L200 -100L200 30L-100 30Z" style={{ animation: 'path1-light 11.904761904761905s linear infinite alternate' }} />
          <path fill="#528e10" fill-opacity="0.1" d="M-100 -100L200 -100L200 20L-100 20Z" style={{ animation: 'path2-light 3.4722222222222223s linear infinite alternate' }} />
        </svg>
      );
    }
  };

  return renderSVG(colorMode);
};

export default LoginBackground;
