import {
  SET_PLATFORM,
  UPDATE_PLATFORM,
  START_LOADING,
  END_LOADING,
  SET_ERROR,
} from '../actions/types';

const initialState = {
  platform: {},
  loading: false,
  error: null,
};

const platformReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLATFORM:
      return {
        ...state,
        platform: action.payload,
      };
    case UPDATE_PLATFORM:
      return {
        ...state,
        platform: {
          ...state.platform,
          ...(action.payload.status_complete !== undefined && { status_complete: action.payload.status_complete }),
          ...(action.payload.status_reviewed !== undefined && { status_reviewed: action.payload.status_reviewed }),
        },
      };
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING:
      return {
        ...state,
        loading: false,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default platformReducer;