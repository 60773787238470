import React, { lazy, Suspense } from 'react';
import { Heading, Box, Skeleton } from "@chakra-ui/react";
import GeneralCard from "../components/ui/GeneralCard";
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';

const FileUploader = lazy(() => import("../components/files/FileUploader"));
const FileList = lazy(() => import("../components/files/FileList"));

const FilesPage = () => {
  return (
    <MasterUI>
      <Helmet>
        <title>Storage</title>
      </Helmet>
      <GeneralCard>
        <Heading>
          Files
        </Heading>
        <Suspense fallback={<Skeleton height="20px" />}>
          <FileUploader />
        </Suspense>
      </GeneralCard>
      <Box p={2} />
      <GeneralCard>
        <Heading>
          Uploaded Files
        </Heading>
        <Suspense fallback={<Skeleton height="20px" />}>
          <FileList />
        </Suspense>
      </GeneralCard>
    </MasterUI>
  );
}

export default FilesPage;
