import axios from 'axios';
import { FETCH_ANNOUNCEMENTS, ADD_ANNOUNCEMENT, DELETE_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT } from './types';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchAnnouncements = () => async dispatch => {
    const response = await axios.get(`${API_BASE_URL}/home/announcements`);
    dispatch({ type: FETCH_ANNOUNCEMENTS, payload: response.data });
};

export const addAnnouncement = (title, body) => async dispatch => {
    const response = await axios.post(`${API_BASE_URL}/home/new-announcement`, { title, body });
    dispatch({ type: ADD_ANNOUNCEMENT, payload: response.data });
};

export const deleteAnnouncement = (id) => async dispatch => {
    try {
        await axios.delete(`${API_BASE_URL}/home/delete-announcement/${id}`);
        dispatch({ type: DELETE_ANNOUNCEMENT, payload: id });
    } catch (error) {
        console.error("Error deleting the announcement:", error);
        // You can also dispatch an error action here if you have one.
    }
};

export const updateAnnouncement = (id, title, body) => async dispatch => {
    try {
        const response = await axios.put(`${API_BASE_URL}/home/edit-announcement/${id}`, { title, body });
        dispatch({ type: UPDATE_ANNOUNCEMENT, payload: response.data });
    } catch (error) {
        console.error("Error updating the announcement:", error);
        // You can also dispatch an error action here if you have one.
    }
};
