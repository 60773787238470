import React from 'react';
import { Box, Heading, Divider, useColorModeValue, Table, Thead, Tbody, Tr, Th, Td, Button, HStack, Center, Spacer } from '@chakra-ui/react';
import { useTable, useSortBy, usePagination } from 'react-table';

const AppUIDataTable = ({ columns, data, title, lightModeBgColor, darkModeBgColor, showDivider = true }) => {
  const bgColor = useColorModeValue(lightModeBgColor, darkModeBgColor);

  // Define the buttonColor constant for individual buttons
  const buttonColor = useColorModeValue("#D6BCFA", "#6B46C1");
  const buttonHoverColor = useColorModeValue("#E9D8FD", "#9F7AEA");
  const buttonSelectedColor = useColorModeValue("#B794F4", "#B794F4");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, 
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 }, 
    },
    useSortBy,
    usePagination
  );

  return (
    <Box bgColor={bgColor} w="100%">
      {title && <Heading size="md" p={3}>{title}</Heading>}
      <HStack spacing={2} px={4} py={2}>
        <Button 
          onClick={() => previousPage()} 
          disabled={!canPreviousPage}
          size="sm" 
          bg={buttonColor}
          _hover={{ bg: buttonHoverColor }}
          _active={{ bg: buttonSelectedColor }}>
          {'←'}
        </Button>
        <Spacer />
        <Button 
          onClick={() => nextPage()}
          disabled={!canNextPage}
          size="sm" 
          bg={buttonColor}
          _hover={{ bg: buttonHoverColor }}
          _active={{ bg: buttonSelectedColor }}>
          {'→'}
        </Button>
      </HStack>
      <Box overflowX="auto">
        <Table {...getTableProps()} size="sm">
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())} width={column.width}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <Center p={4}>
        <strong>
          {pageIndex + 1} of {pageCount}
        </strong>
      </Center>
      {showDivider && <Divider />}
    </Box>
  );
};

export default AppUIDataTable;
