import { FETCH_ANNOUNCEMENTS, ADD_ANNOUNCEMENT, DELETE_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT } from '../actions/types';

const initialState = [];

const announcementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ANNOUNCEMENTS:
            return action.payload;
        case ADD_ANNOUNCEMENT:
            return [action.payload, ...state];
        case DELETE_ANNOUNCEMENT:
            return state.filter(announcement => announcement.id !== action.payload);
        case UPDATE_ANNOUNCEMENT:
            return state.map(announcement => 
                announcement.id === action.payload.id ? action.payload : announcement
            );
        default:
            return state;
    }
}

export default announcementsReducer;
