import React, { useEffect, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Skeleton } from '@chakra-ui/react';
import GeneralCard from "../components/ui/GeneralCard";
import MasterUI from '../components/ui/MasterUI';
import { fetchAnnouncements } from '../actions/announcements';  // Import the action
import { Helmet } from 'react-helmet';

// Lazy loaded components
const AnnouncementManager = React.lazy(() => import('../components/home/AnnouncementManager'));
const AnnouncementListItem = React.lazy(() => import('../components/home/announcements/AnnouncementListItem'));

const HomePage = () => {
  const dispatch = useDispatch();
  const announcements = useSelector(state => state.announcements);
  const roles = useSelector(state => state.user.roles) || [];
  const isAdmin = roles.some(role => role.toLowerCase() === 'admin');

  useEffect(() => {
    dispatch(fetchAnnouncements());
  }, [dispatch]);

  return (
    <MasterUI>
      <Helmet>
        <title>shot.to</title>
      </Helmet>

      {isAdmin && (
        <Box pb={4}>
          <GeneralCard>
            <Suspense fallback={<Skeleton height="20px" width="100%" />}>
              <AnnouncementManager />
            </Suspense>
          </GeneralCard>
        </Box>
      )}

      {announcements.map((announcement, index) => (
        <Box mb={index === announcements.length - 1 ? 0 : 4} key={announcement.id}>
          <GeneralCard>
            <Suspense fallback={<Skeleton height="20px" width="100%" />}>
              <AnnouncementListItem announcement={announcement} />
            </Suspense>
          </GeneralCard>
        </Box>
      ))}
    </MasterUI>
  );
}

export default HomePage;
