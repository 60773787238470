export const SET_FLAGGED_POSTS = 'SET_FLAGGED_POSTS';
export const SET_UNFLAGGED_POSTS = 'SET_UNFLAGGED_POSTS';
export const ADD_FLAGGED_POSTS = 'ADD_FLAGGED_POSTS';
export const ADD_UNFLAGGED_POSTS = 'ADD_UNFLAGGED_POSTS';
export const SET_REVIEWED_POSTS_COUNT = 'SET_REVIEWED_POSTS_COUNT';
export const UPDATE_POST = 'UPDATE_POST';
export const SET_FLAGGED_POSTS_COUNT = 'SET_FLAGGED_POSTS_COUNT';
export const SET_UNFLAGGED_POSTS_COUNT = 'SET_UNFLAGGED_POSTS_COUNT';
export const INCREMENT_REVIEWED_POSTS_COUNT = 'INCREMENT_REVIEWED_POSTS_COUNT';
export const DECREMENT_REVIEWED_POSTS_COUNT = 'DECREMENT_REVIEWED_POSTS_COUNT';
export const INCREMENT_FLAGGED_POSTS_COUNT = 'INCREMENT_FLAGGED_POSTS_COUNT';
export const DECREMENT_FLAGGED_POSTS_COUNT = 'DECREMENT_FLAGGED_POSTS_COUNT';
export const INCREMENT_UNFLAGGED_POSTS_COUNT = 'INCREMENT_UNFLAGGED_POSTS_COUNT';
export const DECREMENT_UNFLAGGED_POSTS_COUNT = 'DECREMENT_UNFLAGGED_POSTS_COUNT';

export const SET_PLATFORM = 'SET_PLATFORM';
export const UPDATE_PLATFORM = 'UPDATE_PLATFORM';

export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const SET_ERROR = 'SET_ERROR';

// User action types
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const RESET_POSTS = 'RESET_POSTS';
export const LOGOUT_USER = 'LOGOUT_USER';

// YTTS Action Types
export const FETCH_YTTS_PROJECTS = 'FETCH_YTTS_PROJECTS';
export const CREATE_YTTS_PROJECT = 'CREATE_YTTS_PROJECT';
export const UPDATE_YTTS_PROJECT = 'UPDATE_YTTS_PROJECT';
export const DELETE_YTTS_PROJECT = 'DELETE_YTTS_PROJECT';
export const FETCH_YTTS_SEARCH_RESULTS = 'FETCH_YTTS_SEARCH_RESULTS';
export const PERFORM_YTTS_SEARCH = 'PERFORM_YTTS_SEARCH';
export const FETCH_YTTS_DOWNLOAD_STATUS = 'FETCH_YTTS_DOWNLOAD_STATUS';
export const UPDATE_YTTS_DOWNLOAD_STATUS = 'UPDATE_YTTS_DOWNLOAD_STATUS';
export const DELETE_YTTS_SEARCH_RESULT = 'DELETE_YTTS_SEARCH_RESULT';
export const INITIATE_YTTS_CHANNEL_DOWNLOAD = 'INITIATE_YTTS_CHANNEL_DOWNLOAD';
export const DELETE_YTTS_CHANNEL = 'DELETE_YTTS_CHANNEL';
export const FETCH_YTTS_CHANNELS = 'FETCH_YTTS_CHANNELS';
export const SELECT_PROJECT = 'SELECT_PROJECT';

export const FETCH_YTTS_PROJECT_PULLS = 'FETCH_YTTS_PROJECT_PULLS';
export const ADD_YTTS_PROJECT_PULL = 'ADD_YTTS_PROJECT_PULL';
export const DELETE_YTTS_PROJECT_PULL = 'DELETE_YTTS_PROJECT_PULL';

export const SET_VIDEO_DETAILS = 'SET_VIDEO_DETAILS';
export const SET_SELECTED_SEARCH_RESULT = 'SET_SELECTED_SEARCH_RESULT';

export const SET_SELECTED_PULL = 'SET_SELECTED_PULL';

export const FETCH_SURROUNDING_TRANSCRIPTS = 'FETCH_SURROUNDING_TRANSCRIPTS';

// GMaps Action Types
export const SELECT_GMAPS_PROJECT = 'SELECT_GMAPS_PROJECT';
export const FETCH_GMAPS_PROJECT = 'FETCH_GMAPS_PROJECT';
export const CREATE_GMAPS_PROJECT = 'CREATE_GMAPS_PROJECT';
export const UPDATE_GMAPS_PROJECT = 'UPDATE_GMAPS_PROJECT';
export const DELETE_GMAPS_PROJECT = 'DELETE_GMAPS_PROJECT';
export const FETCH_GMAPS_PROJECT_OUTREACH = 'FETCH_GMAPS_PROJECT_OUTREACH';
export const DELETE_GMAPS_PROJECT_OUTREACH = 'DELETE_GMAPS_PROJECT_OUTREACH';
export const SET_SELECTED_OUTREACH = 'SET_SELECTED_OUTREACH';

// RAU Action Types
export const SET_TIMEFRAME = 'SET_TIMEFRAME';
export const SET_CHART_DATA = 'SET_CHART_DATA';
export const SET_SUBREDDIT = 'SET_SUBREDDIT';
export const SET_SUBREDDIT_LIST = 'SET_SUBREDDIT_LIST';
export const TOGGLE_SUBREDDIT_MANAGER = 'TOGGLE_SUBREDDIT_MANAGER';
export const UPDATE_REALTIME_CHART_DATA = 'UPDATE_REALTIME_CHART_DATA';
export const TOGGLE_CHART_TYPE = 'TOGGLE_CHART_TYPE';
export const UPDATE_ACTIVE_USER_COUNT = 'UPDATE_ACTIVE_USER_COUNT';

// RAU Grid View
export const FETCH_SUBREDDITS = 'FETCH_SUBREDDITS';
export const UPDATE_ACTIVE_USERS_GRID = 'UPDATE_ACTIVE_USERS_GRID';
export const SET_PREVIEW_CHART_DATA = 'SET_PREVIEW_CHART_DATA';

// ScriptSync Action Types
export const SELECT_SCRIPTSYNC_PROJECT = 'SELECT_SCRIPTSYNC_PROJECT';
export const FETCH_SCRIPTSYNC_PROJECT = 'FETCH_SCRIPTSYNC_PROJECT';
export const CREATE_SCRIPTSYNC_PROJECT = 'CREATE_SCRIPTSYNC_PROJECT';
export const UPDATE_SCRIPTSYNC_PROJECT = 'UPDATE_SCRIPTSYNC_PROJECT';
export const DELETE_SCRIPTSYNC_PROJECT = 'DELETE_SCRIPTSYNC_PROJECT';

export const FETCH_SCRIPTSYNC_BINS = 'FETCH_SCRIPTSYNC_BINS';
export const CREATE_SCRIPTSYNC_BIN = 'CREATE_SCRIPTSYNC_BIN';
export const SELECT_SCRIPTSYNC_BIN = 'SELECT_SCRIPTSYNC_BIN';

export const FETCH_SCRIPTSYNC_INTERVIEWS = 'FETCH_SCRIPTSYNC_INTERVIEWS';
export const SELECT_SCRIPTSYNC_INTERVIEW = 'SELECT_SCRIPTSYNC_INTERVIEW';

// UI action types
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_SIDEBAR_STATE = 'SET_SIDEBAR_STATE';
export const SET_USER_TOGGLED = 'SET_USER_TOGGLED';
export const UI_LOADED = 'UI_LOADED';

// Announcement Action Types
export const FETCH_ANNOUNCEMENTS = 'FETCH_ANNOUNCEMENTS';
export const ADD_ANNOUNCEMENT = 'ADD_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';

// UserRadar Action Types
export const UPDATE_ACTIVE_USERS = 'UPDATE_ACTIVE_USERS';

