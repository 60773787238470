import React, { Suspense, lazy } from 'react';
import {
    Box,
    Flex,
    useMediaQuery,
    Skeleton
} from "@chakra-ui/react";
import GeneralCard from "../components/ui/GeneralCard";
import { useSelector } from 'react-redux';
import { getShowSubredditManager } from '../selectors/raugrid';
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';

// Use React.lazy to create a lazy-version of the component
const LazyRAUHeader = lazy(() => import('../components/reddit-tracker/RAUHeader'));
const LazySubredditGridManager = lazy(() => import('../components/reddit-tracker/SubredditGridManager'));

const SubredditPageDev = () => {
    const showSubredditManager = useSelector(getShowSubredditManager);
    const [isNarrowScreen] = useMediaQuery("(max-width: 1490px)");

    return (
        <MasterUI>
            <Helmet>
                <title>Subreddit Activity Grid View</title>
            </Helmet>
            <Flex direction="column" align="stretch" width="100%">
                {/* RAUHeader Section */}
                <GeneralCard width="100%">
                    {/* Wrap the lazy component with Suspense and use Skeleton for fallback */}
                    <Suspense fallback={<Skeleton isLoaded={false} />}>
                        <LazyRAUHeader />
                    </Suspense>
                </GeneralCard>
                {/* Spacing Box */}
                <Box p={2} />
                {/* Content Section */}
                <Flex width="100%" height="100%" direction="row">
                    {/* Wrap the lazy component with Suspense and use Skeleton for fallback */}
                    <Suspense fallback={<Skeleton isLoaded={false} />}>
                        <LazySubredditGridManager />
                    </Suspense>
                </Flex>
            </Flex>
        </MasterUI>
    );
}

export default SubredditPageDev;
