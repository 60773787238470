// src/pagesConfig.js

export const availablePages = [
    'DashboardPage',
    'SettingsPage',
    'SubredditPage',
    'GMapsPage',
    'YTGeoSearchPage',
    'YTTranscriptPage',
    'FilesPage',
    'SharedFilesPage',
    'AdminUsersPage',
    'AdminRolesPage',
    'AdminActivityPage'
  ];
  