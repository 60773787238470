import React, { useState, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, VStack, Skeleton } from "@chakra-ui/react";
import GeneralCard from "../components/ui/GeneralCard";
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';

// Lazy loaded components
const GmapsToolbar = React.lazy(() => import('../components/gmaps/GmapsToolbar'));
const ProjectManager = React.lazy(() => import('../components/gmaps/ProjectManager'));
const SearchManager = React.lazy(() => import('../components/gmaps/SearchManager'));
const OutreachManager = React.lazy(() => import('../components/gmaps/OutreachManager'));

const GMapsPage = () => {
  const [isProjectManagerVisible, setProjectManagerVisible] = useState(false);
  const selectedProject = useSelector(state => state.gmaps.selectedProject); // Update the selector

  // Function to toggle Project Manager visibility
  const toggleProjectManager = () => setProjectManagerVisible(!isProjectManagerVisible);

  return (
    <MasterUI>
      <Helmet>
        <title>Gmaps API</title>
      </Helmet>
      <GeneralCard>
        <Suspense fallback={<Skeleton height="20px" width="100%" />}>
          <GmapsToolbar 
            toggleProjectManager={toggleProjectManager}
            isProjectManagerVisible={isProjectManagerVisible}
            selectedProject={selectedProject}
          />
        </Suspense>
      </GeneralCard>
      <Box pt={4}></Box>
      {selectedProject ? (
        <>
          <Flex width="100%" overflowX="hidden">
            <VStack spacing={4} align="stretch" flex="1">
              <GeneralCard>
                <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                  <SearchManager />
                </Suspense>
              </GeneralCard>
            </VStack>
            <Box p={2}></Box>
            <VStack spacing={4} align="stretch" flex="1">
              <GeneralCard>
                <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                  <OutreachManager />
                </Suspense>
              </GeneralCard>
            </VStack>
          </Flex>
        </>
      ) : (
        <VStack spacing={4} align="stretch">
          <GeneralCard>
            <Suspense fallback={<Skeleton height="20px" width="100%" />}>
              <ProjectManager />
            </Suspense>
          </GeneralCard>
        </VStack>
      )}
    </MasterUI>
  );
};

export default GMapsPage;
