import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Box, VStack, Flex, Skeleton } from '@chakra-ui/react';
import GeneralCard from '../components/ui/GeneralCard';
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';

const StatActiveUsers = lazy(() => import('../components/admin/statActiveUsers'));
const UserRadar = lazy(() => import('../components/admin/UserRadar'));
const LogViewer = lazy(() => import('../components/admin/LogViewer'));

const LoadingSkeleton = () => (
    <Skeleton isLoaded={false} height="20px" mb="10px" />
);

const AdminActivityPage = () => {
  return (
    <MasterUI>
      <Helmet>
        <title>User Activity</title>
      </Helmet>
      <Suspense fallback={LoadingSkeleton()}>
        <GeneralCard>
          <StatActiveUsers />
        </GeneralCard>
      </Suspense>
      <Box pt={4}></Box>
      <Flex width="100%" overflowX="hidden">
        <VStack spacing={4} align="stretch" flex="1">
          <Suspense fallback={LoadingSkeleton()}>
            <GeneralCard>
              <UserRadar />
            </GeneralCard>
          </Suspense>
        </VStack>
        <Box p={2}></Box>
        <VStack spacing={4} align="stretch" flex="1">
          <Suspense fallback={LoadingSkeleton()}>
            <GeneralCard>
              <LogViewer />
            </GeneralCard>
          </Suspense>
        </VStack>
      </Flex>
    </MasterUI>
  );
};

export default AdminActivityPage;