import { UPDATE_ACTIVE_USERS } from '../actions/types';

const initialState = {
    activeUsers: []
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_ACTIVE_USERS:
            console.log("Updating active users in reducer:", action.payload);
            return {
                ...state,
                activeUsers: action.payload
            };        
        default:
            return state;
    }
};

export default adminReducer;
