import React, { useState, useEffect, lazy, Suspense } from 'react';
import {
    Heading,
    Skeleton
} from "@chakra-ui/react";
import GeneralCard from "../components/ui/GeneralCard"; // Import SidebarWithHeader
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';

const SharedFilesList = lazy(() => import("../components/files/SharedFilesList"));

const SharedFilesPage = () => {
  const [sharedFiles, setSharedFiles] = useState([]);

  // Fetch the shared files from your API
  useEffect(() => {
    fetch('/api/sharedFiles')
      .then(response => response.json())
      .then(data => setSharedFiles(data));
  }, []);

  return (
      <MasterUI>
      <Helmet>
        <title>Shared Storage</title>
      </Helmet>
        <GeneralCard>
          <Heading>
            Shared Files
          </Heading>
          <Suspense fallback={<Skeleton height="20px" />}>
            <SharedFilesList files={sharedFiles} />
          </Suspense>
        </GeneralCard>
      </MasterUI>
  );
}

export default SharedFilesPage;
