import React, { useState, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Flex, VStack, Box, Skeleton } from "@chakra-ui/react";
import GeneralCard from "../components/ui/GeneralCard";
import PlayerCard from "../components/ui/PlayerCard";
import { getSelectedProject } from '../selectors/ytts';
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';

const ProjectManager = React.lazy(() => import("../components/ytts/ProjectManager"));
const ChannelManager = React.lazy(() => import("../components/ytts/ChannelManager"));
const PullsManager = React.lazy(() => import("../components/ytts/PullsManager"));
const SearchManager = React.lazy(() => import("../components/ytts/SearchManager"));
const PlayerManager = React.lazy(() => import("../components/ytts/PlayerManager"));
const YttsToolbar = React.lazy(() => import("../components/ytts/YttsToolbar"));
const TranscriptManager = React.lazy(() => import('../components/ytts/TranscriptManager'));

const YTTranscriptPageDev = () => {
  const [isChannelManagerVisible, setChannelManagerVisible] = useState(false);
  const selectedProject = useSelector(getSelectedProject);

  // Function to toggle Channel Manager visibility
  const toggleChannelManager = () => setChannelManagerVisible(!isChannelManagerVisible);

  return (
    <MasterUI>
      <Helmet>
        <title>YTFTS</title>
      </Helmet>
      <GeneralCard>
        <Suspense fallback={<Skeleton isLoaded={false} />}>
          <YttsToolbar 
            toggleChannelManager={toggleChannelManager}
            isChannelManagerVisible={isChannelManagerVisible}
            selectedProject={selectedProject}
          />
        </Suspense>
      </GeneralCard>
      <Box pt={4}>
      </Box>
      {selectedProject ? (
        <>
          <Flex direction="row" alignItems="start">
            <GeneralCard flex="1">
              <Suspense fallback={<Skeleton isLoaded={false} />}>
                <TranscriptManager />
              </Suspense>
            </GeneralCard>
            <Box p={2}>
            </Box>
            <PlayerCard flex="1">
              <Suspense fallback={<Skeleton isLoaded={false} />}>
                <PlayerManager />
              </Suspense>
            </PlayerCard>
          </Flex>
          <Box pt={4}>
          </Box>
          <Flex width="100%" overflowX="hidden">
            <VStack spacing={4} align="stretch" flex="1">
              <GeneralCard>
                <Suspense fallback={<Skeleton isLoaded={false} />}>
                  <SearchManager />
                </Suspense>
              </GeneralCard>
              {isChannelManagerVisible && (
                <GeneralCard>
                  <Suspense fallback={<Skeleton isLoaded={false} />}>
                    <ChannelManager />
                  </Suspense>
                </GeneralCard>
              )}
            </VStack>
            <Box p={2}>
            </Box>
            <VStack spacing={4} align="stretch" flex="1">
              <GeneralCard>
                <Suspense fallback={<Skeleton isLoaded={false} />}>
                  <PullsManager />
                </Suspense>
              </GeneralCard>
            </VStack>
          </Flex>
        </>
      ) : (
        <VStack spacing={4} align="stretch">
          <GeneralCard>
            <Suspense fallback={<Skeleton isLoaded={false} />}>
              <ProjectManager />
            </Suspense>
          </GeneralCard>
        </VStack>
      )}
    </MasterUI>
  );  
}

export default YTTranscriptPageDev;
