import {
  SELECT_SCRIPTSYNC_PROJECT,
  FETCH_SCRIPTSYNC_PROJECT,
  CREATE_SCRIPTSYNC_PROJECT,
  UPDATE_SCRIPTSYNC_PROJECT,
  DELETE_SCRIPTSYNC_PROJECT,
  FETCH_SCRIPTSYNC_BINS,
  CREATE_SCRIPTSYNC_BIN,
  FETCH_SCRIPTSYNC_INTERVIEWS,
  SELECT_SCRIPTSYNC_INTERVIEW,
  SELECT_SCRIPTSYNC_BIN,
} from '../actions/types';

const initialState = {
  projects: [],
  selectedProject: null,
  bins: [],
  selectedBin: null,
  interviews: [],
};

export default function scriptsyncReducer(state = initialState, action) {
switch (action.type) {
  case SELECT_SCRIPTSYNC_PROJECT:
    if (action.payload === null) {
      // If the project is deselected, also deselect the interview and bin.
      return { 
        ...state, 
        selectedProject: null,
        selectedInterview: null,
        selectedBin: null
      };
    }
    // If a project is selected, retain the current behavior.
    return { ...state, selectedProject: action.payload };
  case FETCH_SCRIPTSYNC_PROJECT:
    return { ...state, projects: action.payload };
  case CREATE_SCRIPTSYNC_PROJECT:
    return { ...state, projects: [...state.projects, action.payload] };
  case UPDATE_SCRIPTSYNC_PROJECT:
    return {
      ...state,
      projects: state.projects.map((project) =>
        project.id === action.payload.id ? action.payload : project
      ),
    };
  case FETCH_SCRIPTSYNC_BINS:
    return { ...state, bins: action.payload };
  case CREATE_SCRIPTSYNC_BIN:
    return { ...state, bins: [...state.bins, action.payload] };
  case FETCH_SCRIPTSYNC_INTERVIEWS:
    return { ...state, interviews: action.payload };
  case SELECT_SCRIPTSYNC_INTERVIEW:
    return { ...state, selectedInterview: action.payload };
  case SELECT_SCRIPTSYNC_BIN:
    return { ...state, selectedBin: action.payload };
  case DELETE_SCRIPTSYNC_PROJECT:
    return {
      ...state,
      projects: state.projects.filter((project) => project.id !== action.payload),
    };
  default:
    return state;
}
}
