import { IconButton, useColorMode } from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';

function MUIDarkModeSwitch() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <IconButton
    icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
    onClick={toggleColorMode}
    variant="ghost"
    _active={{ bg: 'transparent' }}
    _focus={{ boxShadow: 'none', bg: 'transparent' }}
    />
  );
}

export default MUIDarkModeSwitch;
