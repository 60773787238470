import { extendTheme } from '@chakra-ui/react';
import { theme as saasTheme } from '@saas-ui/theme';

const customTheme = extendTheme({
  ...saasTheme,

  colors: {
    ...saasTheme.colors,
    custom: {
      light: '#F5F5F5', // Background color for light mode
      dark: '#1d2025',  // Background color for dark mode
    },
  },

  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? 'custom.dark' : 'custom.light',
      },
    }),
  },

  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
});

export default customTheme;
