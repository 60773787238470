import React, { lazy, Suspense, useRef } from 'react';
import {
  Box,
  Skeleton,
} from '@chakra-ui/react';
import GeneralCard from '../components/ui/GeneralCard';
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';

const AddUsers = lazy(() => import('../components/admin/AddUsers'));
const OpenInvitesTable = lazy(() => import('../components/admin/OpenInvitesTable'));

const AdminUsersPage = () => {
  const openInvitesTableRef = useRef(null);

  const handleInviteCreated = () => {
    openInvitesTableRef.current.refreshInvites();
  };

  return (
    <MasterUI>
      <Helmet>
        <title>User Management</title>
      </Helmet>
      <Suspense fallback={<Skeleton height="20px" />}>
        <GeneralCard marginBottom={2}>
          <AddUsers onInviteCreated={handleInviteCreated} />
        </GeneralCard>
      </Suspense>
      <Box p={2} />
      <Suspense fallback={<Skeleton height="20px" />}>
        <GeneralCard>
          <OpenInvitesTable ref={openInvitesTableRef} />
        </GeneralCard>
      </Suspense>
    </MasterUI>
  );  
};

export default AdminUsersPage;
