import React from 'react';
import { Button, HStack, Divider, Box, useColorModeValue } from '@chakra-ui/react';

const AppUIButtonRow = ({ buttons, showDivider = true, lightModeBgColor, darkModeBgColor }) => {
  const bgColor = useColorModeValue(lightModeBgColor, darkModeBgColor);

  // Define the buttonColor constant for individual buttons
  const buttonColor = useColorModeValue("#D6BCFA", "#6B46C1");
  const buttonHoverColor = useColorModeValue("#E9D8FD", "#9F7AEA");
  const buttonSelectedColor = useColorModeValue("#B794F4", "#B794F4");

  return (
    <Box bgColor={bgColor}>
      <HStack spacing={2} p={4}>
        {buttons.map((button, index) => (
          <Button 
            key={index} 
            onClick={button.onClick} 
            size="sm" 
            bg={buttonColor}
            _hover={{ bg: buttonHoverColor }}
            _active={{ bg: buttonSelectedColor }}>
            {button.label}
          </Button>
        ))}
      </HStack>
      {showDivider && <Divider />}
    </Box>
  );
};

export default AppUIButtonRow;
