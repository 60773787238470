// actions/appmenu.js
import {
  TOGGLE_SIDEBAR,
  SET_SIDEBAR_STATE,
  SET_USER_TOGGLED,
  UI_LOADED,
} from './types';

export const toggleSidebar = () => {
  return {
    type: TOGGLE_SIDEBAR,
  };
};

export const setSidebarState = (isOpen) => {
  return {
    type: SET_SIDEBAR_STATE,
    payload: isOpen,
  };
};

export const setUserToggled = (hasToggled) => {
  return {
    type: SET_USER_TOGGLED,
    payload: hasToggled,
  };
};

export const setUILoaded = () => {
  return {
    type: UI_LOADED,
  };
};