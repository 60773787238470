import React from 'react';
import { useColorMode } from '@chakra-ui/react';
import './BackgroundGradient.css';

const BackgroundGradient = () => {
  const { colorMode } = useColorMode();

  return <div className={colorMode === 'dark' ? 'background-dark' : 'background-light'}></div>;
};

export default BackgroundGradient;
