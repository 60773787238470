import {
  FETCH_YTTS_PROJECTS,
  CREATE_YTTS_PROJECT,
  UPDATE_YTTS_PROJECT,
  DELETE_YTTS_PROJECT,
  FETCH_YTTS_SEARCH_RESULTS,
  PERFORM_YTTS_SEARCH,
  FETCH_YTTS_DOWNLOAD_STATUS,
  UPDATE_YTTS_DOWNLOAD_STATUS,
  INITIATE_YTTS_CHANNEL_DOWNLOAD,
  DELETE_YTTS_CHANNEL,
  FETCH_YTTS_CHANNELS,
  SELECT_PROJECT,
  FETCH_YTTS_PROJECT_PULLS,
  ADD_YTTS_PROJECT_PULL,
  DELETE_YTTS_PROJECT_PULL,
  SET_VIDEO_DETAILS,
  SET_SELECTED_SEARCH_RESULT,
  SET_SELECTED_PULL,
  FETCH_SURROUNDING_TRANSCRIPTS,
} from '../actions/types';

const initialState = {
  projects: [],
  searchResults: [],
  downloadStatus: [],
  channels: [],
  selectedProject: null,
  projectPulls: [],
  selectedSearchResult: null,
  surroundingTranscripts: [],
};

export default function yttsReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_PROJECT:
      return { ...state, selectedProject: action.payload };
    case FETCH_YTTS_PROJECTS:
      return { ...state, projects: action.payload };
    case CREATE_YTTS_PROJECT:
      return { ...state, projects: [...state.projects, action.payload] };
    case UPDATE_YTTS_PROJECT:
      return {
        ...state,
        projects: state.projects.map((project) =>
          project.id === action.payload.id ? action.payload : project
        ),
      };
    case DELETE_YTTS_PROJECT:
      return {
        ...state,
        projects: state.projects.filter((project) => project.id !== action.payload),
      };
    case FETCH_YTTS_SEARCH_RESULTS:
      return { ...state, searchResults: action.payload };
    case PERFORM_YTTS_SEARCH:
      return { ...state, searchResults: [...state.searchResults, ...action.payload] };
    case FETCH_YTTS_DOWNLOAD_STATUS:
      return { ...state, downloadStatus: action.payload };
    case UPDATE_YTTS_DOWNLOAD_STATUS:
      return {
        ...state,
        downloadStatus: state.downloadStatus.map((status) => (status.id === action.payload.id ? action.payload : status)),
      };
    case DELETE_YTTS_CHANNEL:
      return { ...state, channels: state.channels.filter((channel) => channel.id !== action.payload) };
    case FETCH_YTTS_CHANNELS:
      return { ...state, channels: action.payload };
    case INITIATE_YTTS_CHANNEL_DOWNLOAD:
      return {
        ...state,
        downloadStatus: [...state.downloadStatus, action.payload],
      };
    case FETCH_YTTS_PROJECT_PULLS:
      return { ...state, projectPulls: action.payload };
    case ADD_YTTS_PROJECT_PULL:
      return { ...state, projectPulls: [...state.projectPulls, action.payload] };
    case DELETE_YTTS_PROJECT_PULL:
      return { ...state, projectPulls: state.projectPulls.filter((pull) => pull.id !== action.payload) };
    case SET_VIDEO_DETAILS:
      return { ...state, videoDetails: action.payload };
    case SET_SELECTED_PULL:
      return { ...state, selectedPull: action.payload };    
    case SET_SELECTED_SEARCH_RESULT:
      return { ...state, selectedSearchResult: action.payload };
    case FETCH_SURROUNDING_TRANSCRIPTS:
      return { ...state, surroundingTranscripts: action.payload.transcripts };
    default:
      return state;
  }
}
