import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, FormControl, FormLabel, Input, VStack, useToast, Flex, useColorModeValue } from '@chakra-ui/react';
import DarkModeSwitch from '../components/DarkModeSwitch';
import { Navigate } from 'react-router-dom';
import LoginBackground from '../components/ui/LoginBackground';
import { Helmet } from 'react-helmet';

function LoginPage({ setAuthenticated, handleLogin, loginError }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const bgColor = useColorModeValue('#f1f1f2', 'whiteAlpha.200');
  const toast = useToast();

  const handleSubmit = async (event) => {
      event.preventDefault();
      const successfulLogin = await handleLogin(username, password);
      if (successfulLogin) {
          setTimeout(() => {
              setShouldRedirect(true);
          }, 1000); // delay for 1 second
      } else {
          toast({
              title: "Authentication failed.",
              description: loginError,
              status: "error",
              duration: 9000,
              isClosable: true,
          });
      }
  };

  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

  return (
    <VStack minH={'100vh'} align={'center'} justify={'center'} spacing={6}>
      <Helmet>
        <title>shot.to - Login</title>
      </Helmet>
      <LoginBackground />
      <Flex
        position="absolute"
        right={4}
        top={4}
        alignItems="center"
        justifyContent="flex-end"
      >
        <DarkModeSwitch />
      </Flex>
      <Box 
        rounded={'lg'} 
        p={6} 
        boxShadow="lg" 
        bg={bgColor}
        maxWidth="300px"
        w="full"
      >
        <form onSubmit={handleSubmit}>
          <FormControl id="username" mb={4}>
            <FormLabel>Username</FormLabel>
            <Input 
              type="text"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </FormControl>
          <FormControl id="password" mb={4}>
            <FormLabel>Password</FormLabel>
            <Input 
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FormControl>
          <Button colorScheme="blue" type="submit" mt={4} w="full">
            Submit
          </Button>
        </form>
      </Box>
    </VStack>
  );
}

export default LoginPage;
