import React, { Suspense } from 'react';
import {
    Heading,
    HStack,
    Box,
    Skeleton
} from "@chakra-ui/react";
import GeneralCard from "../components/ui/GeneralCard";
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';

// Lazy loaded components
const StatTotalProjects = React.lazy(() => import('../components/dashboard/stat/StatTotalProjects'));
const StatTotalMyProjects = React.lazy(() => import('../components/dashboard/stat/StatTotalMyProjects'));
const StatTotalSubreddits = React.lazy(() => import('../components/dashboard/stat/StatTotalSubreddits'));

const DashboardPage = () => {
  return (
      <MasterUI>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
        <HStack pb={4} w="100%">
          <Box flex="1" pr={2}>
            <GeneralCard>
                <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                    <StatTotalProjects />
                </Suspense>
            </GeneralCard>
          </Box>
          <Box flex="1" pr={2}>
            <GeneralCard>
                <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                    <StatTotalMyProjects />
                </Suspense>
            </GeneralCard>
          </Box>
          <Box flex="1" pr={2}>
            <GeneralCard>
                <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                    <StatTotalSubreddits />
                </Suspense>
            </GeneralCard>
          </Box>
        </HStack>
        <GeneralCard>
          <Heading fontSize='md'>
            Dashboard
          </Heading>
        </GeneralCard>
      </MasterUI>
  );
}

export default DashboardPage;
