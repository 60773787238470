import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, FormControl, FormLabel, Input, VStack, useToast, Flex, useColorModeValue } from '@chakra-ui/react';
import DarkModeSwitch from '../components/DarkModeSwitch';
import { Navigate, useParams } from 'react-router-dom';
import LoginBackground from '../components/ui/LoginBackground'; // Importing the LoginBackground component
import { Helmet } from 'react-helmet';

function RegisterPage({ setAuthenticated }) {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { inviteId } = useParams();

  const bgColor = useColorModeValue('#f1f1f2', 'whiteAlpha.200');
  const toast = useToast();

  useEffect(() => {
    // Fetch invite data
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/invites/${inviteId}`)
      .then(response => {
        setUsername(response.data.username);
      })
      .catch(error => {
        console.error('Error fetching invite:', error);
      });
  }, [inviteId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register/${inviteId}`, { password });
      if (res.data) {
        setAuthenticated(false); // Set to false as the user needs to log in
        toast({
          title: "Registered.",
          description: "You've been successfully registered. Please log in.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setShouldRedirect(true);
      }
    } catch (err) {
      toast({
        title: "Registration failed.",
        description: "Something went wrong.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  if (shouldRedirect) {
    return <Navigate to="/login" />;
  }

  return (
    <VStack minH={'100vh'} align={'center'} justify={'center'} spacing={6}>
      <Helmet>
        <title>shot.to - Register</title>
      </Helmet>
      <LoginBackground />
      <Flex
        position="absolute"
        right={4}
        top={4}
        alignItems="center"
        justifyContent="flex-end"
      >
        <DarkModeSwitch />
      </Flex>
      <Box 
        rounded={'lg'} 
        p={6} 
        boxShadow="lg" 
        bg={bgColor}
        maxWidth="300px"
        w="full"
      >
        <form onSubmit={handleSubmit}>
          <FormControl id="username" mb={4}>
            <FormLabel>Username</FormLabel>
            <Input 
              type="text"
              value={username}
              isReadOnly
            />
          </FormControl>
          <FormControl id="password" mb={4}>
            <FormLabel>Password</FormLabel>
            <Input 
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FormControl>
          <Button colorScheme="blue" type="submit" mt={4} w="full">
            Register
          </Button>
        </form>
      </Box>
    </VStack>
  );
}

export default RegisterPage;
