import React from 'react';
import { Box, Heading, Divider, Flex, Menu, MenuButton, MenuList, MenuItem, Button, useColorModeValue } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const AppUIHeader = ({ title, showMenuButton = false, menuOptions = [], actionsText = "Actions", showDivider = true }) => {
  const bgColor = useColorModeValue("white", "whiteAlpha.200");

  // Define the buttonColor constant for MenuButton
  const buttonColor = useColorModeValue("#D6BCFA", "#6B46C1");
  const buttonHoverColor = useColorModeValue("#E9D8FD", "#9F7AEA");
  const buttonSelectedColor = useColorModeValue("#B794F4", "#B794F4");

  return (
    <Box bgColor={bgColor}>
      <Flex justify="space-between" align="center" pl={4} pr={4} pt={3} pb={3}>
        <Heading size="md">
          {title}
        </Heading>
        {showMenuButton && (
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              bg={buttonColor}
              _hover={{ bg: buttonHoverColor }}
              _active={{ bg: buttonSelectedColor }}
            >
              {actionsText}
            </MenuButton>
            {/* Increase the z-index for MenuList */}
            <MenuList zIndex={1000}>
              {menuOptions.map((option, idx) => (
                <MenuItem key={idx} icon={option.icon} onClick={option.onClick}>
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </Flex>
      {showDivider && <Divider />}
    </Box>
  );
};

export default AppUIHeader;
