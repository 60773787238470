import React from 'react';
import { Container, useColorModeValue } from '@chakra-ui/react';

function PlayerCard({ children }) {
  const bgColor = useColorModeValue('black', 'black');  // Light mode: #5A5A5A, Dark mode: black
  const textColor = useColorModeValue('white', 'white');  // Text color is white in both modes

  return (
    <Container
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      p={4}
      overflow="hidden"
      alignItems="space-between"
      backgroundColor={bgColor}  // Use dynamic background color
      color={textColor}  // Use dynamic text color
      boxShadow="lg"
      rounded='lg'
      transition="all 0.2s"
      maxWidth="100%"
    >
      {children}
    </Container>
  );
}

export default PlayerCard;
