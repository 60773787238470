import React from 'react';
import { Card, useColorModeValue } from '@chakra-ui/react';

function GeneralCard({ 
    children, 
    hoverEffect = true, 
    hoverColorLight = "rgba(47, 134, 222, 0.8)",  // Default color for light mode
    hoverColorDark = "rgba(66, 153, 225, 0.4)"  // Default color for dark mode
}) {
  const bgColor = useColorModeValue('white', 'whiteAlpha.200');
  const hoverBorderColor = useColorModeValue(hoverColorLight, hoverColorDark);

  return (
    <Card
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      p={3}
      overflow="hidden"
      alignItems="space-between"
      backgroundColor={bgColor}
      boxShadow="lg"
      rounded='lg'
      transition="all 0.2s"
      maxWidth="100%"
      borderWidth="1px"
      _hover={hoverEffect ? { borderColor: hoverBorderColor } : {}}
    >
      {children}
    </Card>
  );
}

export default GeneralCard;
