import React, { useState, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Flex, VStack, Box, Skeleton } from "@chakra-ui/react";
import GeneralCard from "../components/ui/GeneralCard";
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';
import { getSelectedProject } from '../selectors/ytts';

// Lazy loaded components
const PlayerCard = React.lazy(() => import('../components/ui/PlayerCard'));
const ProjectManager = React.lazy(() => import('../components/ytts/ProjectManager'));
const ChannelManager = React.lazy(() => import('../components/ytts/ChannelManager'));
const PullsManager = React.lazy(() => import('../components/ytts/PullsManager'));
const SearchManager = React.lazy(() => import('../components/ytts/SearchManager'));
const PlayerManager = React.lazy(() => import('../components/ytts/PlayerManager'));
const YttsToolbar = React.lazy(() => import('../components/ytts/YttsToolbar'));
const TranscriptManager = React.lazy(() => import('../components/ytts/TranscriptManager'));

const YTTranscriptPage = () => {
  const [isChannelManagerVisible, setChannelManagerVisible] = useState(false);
  const selectedProject = useSelector(getSelectedProject);

  // Function to toggle Channel Manager visibility
  const toggleChannelManager = () => setChannelManagerVisible(!isChannelManagerVisible);

  return (
    <MasterUI>
      <Helmet>
        <title>YTFTS</title>
      </Helmet>
      <GeneralCard>
        <Suspense fallback={<Skeleton height="20px" width="100%" />}>
          <YttsToolbar 
            toggleChannelManager={toggleChannelManager}
            isChannelManagerVisible={isChannelManagerVisible}
            selectedProject={selectedProject}
          />
        </Suspense>
      </GeneralCard>
      <Box pt={4}></Box>
      {selectedProject ? (
        <>
          <Suspense fallback={<Skeleton height="50px" width="100%" />}>
            <PlayerCard>
              <PlayerManager />
            </PlayerCard>
          </Suspense>
          <Box pt={4}></Box>
          <Flex width="100%" overflowX="hidden">
            <VStack spacing={4} align="stretch" flex="1">
              <GeneralCard>
                <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                  <SearchManager />
                </Suspense>
              </GeneralCard>
              {isChannelManagerVisible && (
                <GeneralCard>
                  <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                    <ChannelManager />
                  </Suspense>
                </GeneralCard>
              )}
            </VStack>
            <Box p={2}></Box>
            <VStack spacing={4} align="stretch" flex="1">
              <GeneralCard>
                <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                  <PullsManager />
                </Suspense>
              </GeneralCard>
            </VStack>
          </Flex>
        </>
      ) : (
        <VStack spacing={4} align="stretch">
          <GeneralCard>
            <Suspense fallback={<Skeleton height="20px" width="100%" />}>
              <ProjectManager />
            </Suspense>
          </GeneralCard>
        </VStack>
      )}
    </MasterUI>
  );  
}

export default YTTranscriptPage;
