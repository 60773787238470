import React from 'react';
import {
    Heading
} from "@chakra-ui/react";
import GeneralCard from "../components/ui/GeneralCard"; // Import SidebarWithHeader
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';


const SettingsPage = () => {
  return (
      <MasterUI>
      <Helmet>
        <title>Settings</title>
      </Helmet>
        <GeneralCard>
          <Heading>
            Settings
          </Heading>
        </GeneralCard>
      </MasterUI>
  );
}

export default SettingsPage;
