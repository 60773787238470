import { FETCH_CURRENT_USER, LOGOUT_USER } from '../actions/types';

const initialState = {
  currentUser: 'Unknown User',
  roles: [],
  permissions: []
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER:
      return { 
        ...state, 
        currentUser: action.payload.username, 
        currentUserId: action.payload.id,
        roles: action.payload.roles,
        permissions: action.payload.permissions
      };
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}  

export default userReducer;
