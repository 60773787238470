import React, { useState, useEffect, Suspense } from 'react';
import { Heading, Box, useDisclosure, Skeleton } from '@chakra-ui/react';
import axios from 'axios';
import { availablePages } from '../pagesConfig';
import GeneralCard from '../components/ui/GeneralCard';  // Regular import for GeneralCard
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';

// Lazy loaded components
const RoleCreationForm = React.lazy(() => import('../components/admin/RoleCreationForm'));
const RolesList = React.lazy(() => import('../components/admin/RolesList'));
const RoleEditor = React.lazy(() => import('../components/admin/RoleEditor'));
const UsersRolesTable = React.lazy(() => import('../components/admin/UsersRolesTable'));
const ChangeUserRoleModal = React.lazy(() => import('../components/admin/ChangeUserRoleModal'));

const AdminRolesPage = () => {
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState('');
  const [selectedRole, setSelectedRole] = useState(null);
  const [users, setUsers] = useState([]);
  const [pendingPermissions, setPendingPermissions] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUserRole, setNewUserRole] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchRolesAndUsers = async () => {
      try {
        const rolesResponse = await axios.get(`${API_BASE_URL}/admin/roles`);
        const usersResponse = await axios.get(`${API_BASE_URL}/admin/users`);

        // Fetching permissions for each role
        const rolesWithPermissions = await Promise.all(
          rolesResponse.data.roles.map(async (role) => {
            const permissionsResponse = await axios.get(
              `${API_BASE_URL}/admin/roles/${role.id}/permissions`
            );
            return {
              ...role,
              permissions: permissionsResponse.data.permissions,
            };
          })
        );

        setRoles(rolesWithPermissions);
        setUsers(usersResponse.data.users);
      } catch (err) {
        console.error('Error fetching roles or users:', err);
      }
    };

    fetchRolesAndUsers();
  }, [API_BASE_URL]);

  const handleNewRoleChange = (e) => {
    setNewRole(e.target.value);
  };

  const handleNewRoleSubmit = async () => {
    try {
      await axios.post(`${API_BASE_URL}/admin/roles`, { name: newRole });
      const rolesResponse = await axios.get(`${API_BASE_URL}/admin/roles`);
      setRoles(rolesResponse.data.roles || []);
      setNewRole('');
    } catch (err) {
      console.error('Error creating new role:', err);
    }
  };

  const handlePermissionChange = (permission) => {
    if (!pendingPermissions) return;

    // Toggle the permission in the local state
    setPendingPermissions((prevPermissions) => {
      if (prevPermissions.includes(permission)) {
        return prevPermissions.filter((p) => p !== permission);
      } else {
        return [...prevPermissions, permission];
      }
    });
  };

  const handleSaveChanges = async () => {
    if (!selectedRole || !pendingPermissions) return;

    try {
      await axios.post(`${API_BASE_URL}/admin/roles/${selectedRole.id}/permissions`, {
        permissions: pendingPermissions,
      });

      const rolesResponse = await axios.get(`${API_BASE_URL}/admin/roles`);
      setRoles(rolesResponse.data.roles || []);

      setSelectedRole(null);
      setPendingPermissions(null);
    } catch (err) {
      console.error('Error saving permissions:', err);
    }
  };

  useEffect(() => {
    if (selectedRole) {
      setPendingPermissions([...selectedRole.permissions]);
    }
  }, [selectedRole]);

  const handleUserRowClick = (user) => {
    setSelectedUser(user);
    onOpen();
  };

  const handleChangeUserRole = (e) => {
    setNewUserRole(e.target.value);
  };

  const handleUserRoleSubmit = async () => {
    try {
      // Update this API call to match the new endpoint
      await axios.put(`${API_BASE_URL}/admin/users/${selectedUser.id}/role`, {
        role_id: newUserRole,
      });
      const usersResponse = await axios.get(`${API_BASE_URL}/admin/users`);
      setUsers(usersResponse.data.users);
    } catch (err) {
      console.error('Error updating user role:', err);
    }
    onClose();
  };

  return (
    <MasterUI>
      <Helmet>
        <title>User Roles</title>
      </Helmet>
      <GeneralCard>
        <Heading fontSize='sm' mb={4}>Manage Roles</Heading>
        <Suspense fallback={<Skeleton height="20px" width="100%" />}>
          <RoleCreationForm
            newRole={newRole}
            handleNewRoleChange={handleNewRoleChange}
            handleNewRoleSubmit={handleNewRoleSubmit}
          />
          <RolesList roles={roles} setSelectedRole={setSelectedRole} />
          <RoleEditor
            selectedRole={selectedRole}
            pendingPermissions={pendingPermissions}
            handlePermissionChange={handlePermissionChange}
            handleSaveChanges={handleSaveChanges}
            availablePages={availablePages}
          />
        </Suspense>
      </GeneralCard>
      <Box p={2} />
      <GeneralCard>
        <Suspense fallback={<Skeleton height="20px" width="100%" />}>
          <UsersRolesTable users={users} handleUserRowClick={handleUserRowClick} />
          <ChangeUserRoleModal
            isOpen={isOpen}
            onClose={onClose}
            username={selectedUser?.username}
            roles={roles}
            handleChangeRole={handleChangeUserRole}
            handleSubmit={handleUserRoleSubmit}
          />
        </Suspense>
      </GeneralCard>
    </MasterUI>
  );
};

export default AdminRolesPage;
