export const getProjects = (state) => state.ytts.projects;
export const getSearchResults = (state) => state.ytts.searchResults;
export const getDownloadStatus = (state) => state.ytts.downloadStatus;
export const getChannels = (state) => state.ytts.channels;
export const getSelectedProject = (state) => state.ytts.selectedProject;
export const getProjectPulls = (state) => state.ytts.projectPulls;
export const getVideoDetails = (state) => state.ytts.videoDetails;
export const getSelectedSearchResult = (state) => state.ytts.selectedSearchResult;
export const getSelectedPull = (state) => state.ytts.selectedPull;
export const getSurroundingTranscripts = (state) => state.ytts.surroundingTranscripts;
