import React from 'react';
import { Flex, Box, useBreakpointValue, useColorModeValue, Divider } from '@chakra-ui/react';

const TwoColumnLayout = ({ left, right, spacing = 4 }) => {
  const layoutStyle = useBreakpointValue({ base: "column", md: "row" });
  const dividerColor = useColorModeValue("gray.200", "gray.700");
  const dividerWidth = "1px";

  // Adjust the offset here:
  const offset = -5; // pixels

  return (
    <Flex direction={layoutStyle} align="start" width="100%" spacing={0} position="relative">
      <Box flex="1" width={layoutStyle === "column" ? "100%" : "auto"} pr={layoutStyle === "row" ? `calc(${spacing}px + ${dividerWidth} + ${offset}px)` : 0}>
        {React.cloneElement(left, { showDivider: layoutStyle === "column" })}
      </Box>
      {layoutStyle === "row" && (
        <Box position="absolute" top="0" bottom="0" left="50%" transform="translateX(-50%)" w={dividerWidth} bg={dividerColor} zIndex="1" />
      )}
      <Box flex="1" width={layoutStyle === "column" ? "100%" : "auto"} pl={layoutStyle === "row" ? `calc(${spacing}px + ${dividerWidth} + ${offset}px)` : 0}>
        {React.cloneElement(right, { showDivider: layoutStyle === "column" })}
      </Box>
      {layoutStyle === "row" && (
        <Divider position="absolute" bottom="0" left="0" right="0" zIndex="2" borderColor={dividerColor} />
      )}
    </Flex>
  );
};

export default TwoColumnLayout;
