import React, { useEffect, useState } from 'react';
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';
import AppUIHeader from '../components/appui/header/AppUIHeader';
import AppUIButtonRow from '../components/appui/menu/AppUIButtonRow';
import AppUISearchBar from '../components/appui/search/AppUISearchBar';
import TwoColumnLayout from '../components/appui/layout/TwoColumnLayout';
import AppUIDataTable from '../components/appui/table/AppUIDataTable';
import { AddIcon, ExternalLinkIcon, RepeatIcon, EditIcon } from '@chakra-ui/icons';

const IndustryDataPage = () => {
  // Buttons for AppUIButtonRow.jsx
  const menuButtons = [
    { label: 'Button 1', onClick: () => console.log('Button 1 clicked') },
    { label: 'Button 2', onClick: () => console.log('Button 2 clicked') },
    { label: 'Button 3', onClick: () => console.log('Button 3 clicked') },
  ];

  // Menu Options for AppUIHeader.jsx 
  const headerMenuOptions = [
    { label: 'New Tab', icon: <AddIcon />, onClick: () => console.log('New Tab clicked') },
    { label: 'New Window', icon: <ExternalLinkIcon />, onClick: () => console.log('New Window clicked') },
    { label: 'Open Closed Tab', icon: <RepeatIcon />, onClick: () => console.log('Open Closed Tab clicked') },
    { label: 'Open File...', icon: <EditIcon />, onClick: () => console.log('Open File clicked') },
  ];

  // API logic for AppUISearchBar.jsx
  const handleSearch = (endpoint, query) => {
    console.log(`Searching on endpoint ${endpoint} for query: ${query}`);
    // Here you'd make your API call, using the endpoint and query provided
  };

  // AppUIDataTable Props
  const columns = [
    { 
      Header: 'Original Name', 
      accessor: 'original_name', 
      width: 150,
      Cell: ({ value }) => <strong>{value}</strong> // This line wraps the content in bold
    },
    { 
      Header: 'Poster', 
      accessor: 'poster_path', 
      width: 120,
      Cell: ({ value }) => value ? <img src={value} alt="Poster" width="100" /> : 'N/A'
    },
    { Header: 'Overview', accessor: 'overview', width: 300 },
    { Header: 'Popularity', accessor: 'popularity', width: 40 },
    { Header: 'Vote Average', accessor: 'vote_average', width: 40 },
    { Header: 'Vote Count', accessor: 'vote_count', width: 40 },
    { Header: 'Origin Country', accessor: 'origin_country', width: 40 },
    { Header: 'Air Date', accessor: 'air_date', width: 40 }
  ];

  const [data, setData] = useState([]);

  // AppUIDataTable API Call
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/industry/airingtoday`);
        const result = await response.json();
        setData(result);
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, []);

  // Define the light and dark mode background colors for AppUIButtonRow.jsx
  const buttonRowLightModeBgColor = 'gray.50';
  const buttonRowDarkModeBgColor = 'whiteAlpha.100';

  // Define the light and dark mode background colors for AppUISearchBar.jsx
  const searchBarLightModeBgColor = 'gray.50';
  const searchBarDarkModeBgColor = 'whiteAlpha.100';

  // Define the light and dark mode background colors for AppUIDataTable.jsx
  const dataTableLightModeBgColor = 'gray.50'; 
  const dataTableDarkModeBgColor = 'whiteAlpha.100';

  return (
    <MasterUI noPadding>
      <Helmet>
        <title>Industry View (Beta)</title>
      </Helmet>
      <AppUIHeader title="Industry View (Beta)" showMenuButton={true} menuOptions={headerMenuOptions} actionsText="Actions" />
      <AppUISearchBar 
        onSearch={handleSearch} 
        endpoint="/api/search" 
        lightModeBgColor={searchBarLightModeBgColor} 
        darkModeBgColor={searchBarDarkModeBgColor} 
      />
      <TwoColumnLayout 
        left={<AppUIButtonRow 
                buttons={menuButtons} 
                lightModeBgColor={buttonRowLightModeBgColor} 
                darkModeBgColor={buttonRowDarkModeBgColor} 
              />}
        right={<AppUIButtonRow 
                buttons={menuButtons} 
                lightModeBgColor={buttonRowLightModeBgColor} 
                darkModeBgColor={buttonRowDarkModeBgColor} 
              />}
      />
      <AppUIDataTable 
        columns={columns}
        data={data}
        title="Recently Aired"
        lightModeBgColor={dataTableLightModeBgColor}
        darkModeBgColor={dataTableDarkModeBgColor}
      />
    </MasterUI>
  );
}

export default IndustryDataPage;
