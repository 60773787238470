import {
  SELECT_GMAPS_PROJECT,
  FETCH_GMAPS_PROJECT,
  CREATE_GMAPS_PROJECT,
  UPDATE_GMAPS_PROJECT,
  DELETE_GMAPS_PROJECT,
  FETCH_GMAPS_PROJECT_OUTREACH,
  DELETE_GMAPS_PROJECT_OUTREACH,
  SET_SELECTED_OUTREACH,
} from '../actions/types';

const initialState = {
  projects: [],
  selectedProject: null,
  outreachList: [],
  selectedOutreach: null,  // Added this line for selectedOutreach
};

export default function gmapsReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_GMAPS_PROJECT:
      return { ...state, selectedProject: action.payload };
    case FETCH_GMAPS_PROJECT:
      return { ...state, projects: action.payload };
    case CREATE_GMAPS_PROJECT:
      return { ...state, projects: [...state.projects, action.payload] };
    case UPDATE_GMAPS_PROJECT:
      return {
        ...state,
        projects: state.projects.map((project) =>
          project.id === action.payload.id ? action.payload : project
        ),
      };
    case DELETE_GMAPS_PROJECT:
      return {
        ...state,
        projects: state.projects.filter((project) => project.id !== action.payload),
      };
    case FETCH_GMAPS_PROJECT_OUTREACH:
      return { ...state, outreachList: action.payload };
    case DELETE_GMAPS_PROJECT_OUTREACH:
      return {
        ...state,
        outreachList: state.outreachList.filter((outreach) => outreach.id !== action.payload),
      };
    case SET_SELECTED_OUTREACH:
      return {
        ...state,
        selectedOutreach: action.payload,
      };
    default:
      return state;
  }
}
