import React from 'react';
import {
    Heading
} from "@chakra-ui/react";
import GeneralCard from "../components/ui/GeneralCard"; // Import SidebarWithHeader
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';


const YTGeoSearchPage = () => {
  return (
      <MasterUI>
        <Helmet>
          <title>YT Geo Search</title>
        </Helmet>
        <GeneralCard>
          <Heading>
            YT Geo Search
          </Heading>
        </GeneralCard>
      </MasterUI>
  );
}

export default YTGeoSearchPage;
