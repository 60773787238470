import React, { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const FileDownloadPage = () => {
  const { uuid } = useParams();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/shared-files/${uuid}/download`)
      .then(response => {
        // Handle the file download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file');
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error("There was an error downloading the file", error);
      });
  }, [uuid]);

  return (
    <div>
      <Helmet>
        <title>File Download</title>  {/* Set the title here */}
      </Helmet>
      Downloading file...
    </div>
  );
};

export default FileDownloadPage;
