import React, { Suspense } from 'react';
import {
    Box,
    Flex,
    useMediaQuery,
    Skeleton
} from "@chakra-ui/react";
import GeneralCard from "../components/ui/GeneralCard";
import MasterUI from '../components/ui/MasterUI';
import { useSelector } from 'react-redux';
import { getShowSubredditManager } from '../selectors/rau';
import { Helmet } from 'react-helmet';

// Lazy loaded components
const ChartTest = React.lazy(() => import('../components/reddit-tracker/chart/ChartTest'));
const RAUHeader = React.lazy(() => import('../components/reddit-tracker/RAUHeader'));
const SubredditManager = React.lazy(() => import('../components/reddit-tracker/SubredditManager'));

const SubredditPage = () => {
    const showSubredditManager = useSelector(getShowSubredditManager);
    const [isNarrowScreen] = useMediaQuery("(max-width: 1490px)");

    return (
        <MasterUI>
            <Helmet>
                <title>Subreddit Activity</title>
            </Helmet>
            <Flex direction="column" align="stretch" width="100%">
                
                {/* RAUHeader Section */}
                <GeneralCard width="100%">
                    <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                        <RAUHeader />
                    </Suspense>
                </GeneralCard>
                
                {/* Spacing Box */}
                <Box p={2} />

                {/* Content Section */}
                <Flex width="100%" direction={isNarrowScreen ? "column-reverse" : "row"}>
                    {showSubredditManager && (
                        <Box flex="1" maxW={isNarrowScreen ? "100%" : "16%"} mb={isNarrowScreen ? 2 : 0} mr={!isNarrowScreen && 4}>
                            <GeneralCard>
                                <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                                    <SubredditManager />
                                </Suspense>
                            </GeneralCard>
                        </Box>
                    )}
                    {isNarrowScreen && <Box p={2} />}  {/* This is the spacing box */}
                    {/* Adding key prop to force re-render */}
                    <Box flex="2" width={isNarrowScreen ? "100%" : "auto"} key={isNarrowScreen ? 'narrow' : 'wide'}>
                        <GeneralCard>
                            <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                                <ChartTest />
                            </Suspense>
                        </GeneralCard>
                    </Box>
                </Flex>
            </Flex>
        </MasterUI>
    );
}

export default SubredditPage;
