import {
  SET_FLAGGED_POSTS,
  ADD_FLAGGED_POSTS,
  SET_UNFLAGGED_POSTS,
  ADD_UNFLAGGED_POSTS,
  SET_REVIEWED_POSTS_COUNT,
  UPDATE_POST,
  SET_FLAGGED_POSTS_COUNT,
  SET_UNFLAGGED_POSTS_COUNT,
  INCREMENT_REVIEWED_POSTS_COUNT,
  DECREMENT_REVIEWED_POSTS_COUNT,
  INCREMENT_FLAGGED_POSTS_COUNT,
  DECREMENT_FLAGGED_POSTS_COUNT,
  INCREMENT_UNFLAGGED_POSTS_COUNT,
  DECREMENT_UNFLAGGED_POSTS_COUNT,
  START_LOADING,
  END_LOADING,
  SET_ERROR,
  RESET_POSTS
} from '../actions/types';

const initialState = {
  flaggedPosts: [],
  unflaggedPosts: [],
  reviewedPostsCount: 0,
  flaggedPostsCount: 0,
  unflaggedPostsCount: 0,
  isLoading: false,
  error: null,
};

const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLAGGED_POSTS:
      return {
        ...state,
        flaggedPosts: action.payload,
      };
    case ADD_FLAGGED_POSTS:
      return {
        ...state,
        flaggedPosts: [...state.flaggedPosts, ...action.payload],
      };
    case SET_UNFLAGGED_POSTS:
      return {
        ...state,
        unflaggedPosts: action.payload,
      };
    case ADD_UNFLAGGED_POSTS:
      return {
        ...state,
        unflaggedPosts: [...state.unflaggedPosts, ...action.payload],
      };
    case SET_REVIEWED_POSTS_COUNT:
      return {
        ...state,
        reviewedPostsCount: action.payload,
      };
    case UPDATE_POST:
      const updatedPost = action.payload;
      const isFlagged = updatedPost.flag_override !== null ? updatedPost.flag_override : updatedPost.is_flagged;
    
      const newFlaggedPosts = state.flaggedPosts.map(post => post.post_id === updatedPost.post_id ? updatedPost : post);
      const newUnflaggedPosts = state.unflaggedPosts.map(post => post.post_id === updatedPost.post_id ? updatedPost : post);
    
      if (isFlagged) {
        return {
          ...state,
          flaggedPosts: newFlaggedPosts.sort((a, b) => a.post_id - b.post_id).slice(0, 20), // Get only the first 20 posts
          unflaggedPosts: newUnflaggedPosts.filter(post => post.post_id !== updatedPost.post_id),
        };
      } else {
        return {
          ...state,
          unflaggedPosts: newUnflaggedPosts.sort((a, b) => a.post_id - b.post_id).slice(0, 20), // Get only the first 20 posts
          flaggedPosts: newFlaggedPosts.filter(post => post.post_id !== updatedPost.post_id),
        };
      }
    case SET_FLAGGED_POSTS_COUNT:
      return {
        ...state,
        flaggedPostsCount: action.payload,
      };
    case SET_UNFLAGGED_POSTS_COUNT:
      return {
        ...state,
        unflaggedPostsCount: action.payload,
      };
    case INCREMENT_REVIEWED_POSTS_COUNT:
      return {
        ...state,
        reviewedPostsCount: state.reviewedPostsCount + 1,
      };
    case DECREMENT_REVIEWED_POSTS_COUNT:
      return {
        ...state,
        reviewedPostsCount: state.reviewedPostsCount - 1,
      };
    case DECREMENT_FLAGGED_POSTS_COUNT:
      return {
        ...state,
        flaggedPostsCount: state.flaggedPostsCount - 1,
      };
    case INCREMENT_FLAGGED_POSTS_COUNT:
      return {
        ...state,
        flaggedPostsCount: state.flaggedPostsCount + 1,
      };
    case INCREMENT_UNFLAGGED_POSTS_COUNT:
      return {
        ...state,
        unflaggedPostsCount: state.unflaggedPostsCount + 1,
      };
    case DECREMENT_UNFLAGGED_POSTS_COUNT:
      return {
        ...state,
        unflaggedPostsCount: state.unflaggedPostsCount - 1,
      };
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case END_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_POSTS:
      return {
        ...state,
        flaggedPosts: [],
        unflaggedPosts: [],
      };

    default:
      return state;
  }
};


export default postsReducer;