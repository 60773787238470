import React, { useState, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Box, VStack, Flex, Skeleton } from "@chakra-ui/react";
import GeneralCard from "../components/ui/GeneralCard";
import MasterUI from '../components/ui/MasterUI';
import { Helmet } from 'react-helmet';

// Lazy loaded components
const ScriptSyncToolbar = React.lazy(() => import('../components/scriptsync/ScriptSyncToolbar'));
const ProjectManager = React.lazy(() => import('../components/scriptsync/ProjectManager'));
const BinsManager = React.lazy(() => import('../components/scriptsync/BinsManager'));
const InterviewManager = React.lazy(() => import('../components/scriptsync/InterviewManager'));
const TestComponent = React.lazy(() => import('../components/scriptsync/TestComponent'));

const ScriptSyncPage = () => {
  const [isProjectManagerVisible, setProjectManagerVisible] = useState(false);
  const selectedProject = useSelector(state => state.scriptsync.selectedProject);

  const toggleProjectManager = () => setProjectManagerVisible(!isProjectManagerVisible);

  return (
    <MasterUI>
      <Helmet>
        <title>ScriptSunk</title>
      </Helmet>
      <VStack spacing={4} align="stretch">
        <GeneralCard>
          <Suspense fallback={<Skeleton height="20px" width="100%" />}>
            <ScriptSyncToolbar 
              toggleProjectManager={toggleProjectManager}
              isProjectManagerVisible={isProjectManagerVisible}
              selectedProject={selectedProject}
            />
          </Suspense>
        </GeneralCard>
        <GeneralCard> 
          <Suspense fallback={<Skeleton height="20px" width="100%" />}>
            <TestComponent />
          </Suspense>
        </GeneralCard>
        {selectedProject ? (
          <Flex width="100%" spacing={4}>
            <Box width="50%" pr={2}>
              <GeneralCard>
                <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                  <InterviewManager projectId={selectedProject.id} />
                </Suspense>
              </GeneralCard>
            </Box>
            <Box width="50%" pl={2}>
              <GeneralCard>
                <Suspense fallback={<Skeleton height="20px" width="100%" />}>
                  <BinsManager projectId={selectedProject.id} />
                </Suspense>
              </GeneralCard>
            </Box>
          </Flex>
        ) : (
          <GeneralCard>
            <Suspense fallback={<Skeleton height="20px" width="100%" />}>
              <ProjectManager />
            </Suspense>
          </GeneralCard>
        )}
      </VStack>
    </MasterUI>
  );
};

export default ScriptSyncPage;
