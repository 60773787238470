import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { fetchCurrentUser } from './actions/user';
import HomePage from './pages/Home';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import SettingsPage from './pages/SettingsPage';
import SubredditPage from './pages/SubredditPage';
import GMapsPage from './pages/GMapsPage';
import YTGeoSearchPage from './pages/YTGeoSearchPage';
import YTTranscriptPage from './pages/YTTranscriptPage';
import YTTranscriptPageDev from './pages/YTTranscriptPageDev';
import FilesPage from './pages/FilesPage';
import SharedFilesPage from './pages/SharedFilesPage';
import AdminUsersPage from './pages/AdminUsersPage';
import AdminRolesPage from './pages/AdminRolesPage';
import AdminActivityPage from './pages/AdminActivityPage';
import RegisterPage from './pages/RegisterPage';
import customTheme from './themes/customTheme';
import FileDownloadPage from './pages/FileDownloadPage';
import ScriptSyncPage from './pages/ScriptSyncPage';
import SubredditPageDev from './pages/SubredditPageDev';
import IndustryDataPage from './pages/IndustryDataPage';
import axios from 'axios';
import { SaasProvider } from '@saas-ui/react'
import WebSocketHandler from './components/WebSocketHandler';
import io from './libs/socket.io.min.js'
import BackgroundGradient from './components/ui/BackgroundGradient';

axios.defaults.withCredentials = true;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ProtectedRoute = ({ element, page, ...rest }) => {
  const toast = useToast();
  const permissions = useSelector(state => state.user.permissions);
  const location = useLocation();
  const [lastValidPath, setLastValidPath] = useState('/');

  useEffect(() => {
    if (permissions.includes(page)) {
      setLastValidPath(location.pathname);
    }
  }, [location, permissions, page]);

  const hasPermission = () => {
    if (!permissions.includes(page)) {
      toast({
        title: 'Access Denied',
        description: 'You do not have the permissions to view this page.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  return hasPermission() ? React.cloneElement(element, rest) : <Navigate to={lastValidPath} replace />;
};

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState(null);
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetch(`${API_BASE_URL}/current-user`, { credentials: 'include' })
      .then(response => response.json())
      .then(data => {
        if (data.username) {
          setCurrentUser(data.username);
        }
      })
      .catch(err => console.error(err));
  }, [API_BASE_URL]);

  useEffect(() => {
    if (socket) {
        socket.disconnect();
    }

    if (currentUser) {
        const appTrackerSocket = io('https://www.shot.to/ws/apptracker', {
            path: '/socket.io'
        });
        
        appTrackerSocket.on('connect', () => {
            console.log('WebSocket Client Connected');
            console.log(appTrackerSocket);
        });

        appTrackerSocket.on('disconnect', (reason) => {
            console.log('WebSocket Client Disconnected');
            console.log('Reason: ' + reason);
        });

        appTrackerSocket.on('error', (error) => {
            console.log('Socket.IO Client Error:', error);
        });

        appTrackerSocket.on('ping', () => {
            appTrackerSocket.emit('pong');
        });

        setSocket(appTrackerSocket);

        return () => {
            appTrackerSocket.disconnect();
        };
    }
  }, [currentUser]);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await dispatch(fetchCurrentUser());
        const res = await axios.get(`${API_BASE_URL}/is-authenticated`, { withCredentials: true });
        setAuthenticated(res.data.isAuthenticated);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    checkAuthentication();
  }, [dispatch]);

  const handleLogin = async (username, password) => {
    try {
        const res = await axios.post(`${API_BASE_URL}/login`, { username, password }, { withCredentials: true });
        if (res.data) {
            setAuthenticated(true);
            setCurrentUser(username);
            toast({
                title: "Authenticated.",
                description: "You've been successfully authenticated.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            return true;
        }
    } catch (err) {
        setLoginError("Incorrect username or password.");
        return false;
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  
  return (
    <SaasProvider>
      <ChakraProvider theme={customTheme}>
        <Router>
          <BackgroundGradient /> 
          <WebSocketHandler socket={socket} currentUser={currentUser} />
          <Routes>
            <Route path="/" element={authenticated ? <HomePage /> : <Navigate to="/login" />} />
            <Route path="/login" element={<LoginPage setAuthenticated={setAuthenticated} handleLogin={handleLogin} loginError={loginError} />} />
            <Route path="/dashboard" element={<ProtectedRoute element={<DashboardPage />} page='DashboardPage' />} />
            <Route path="/settings" element={<ProtectedRoute element={<SettingsPage />} page='SettingsPage' />} />
            <Route path="/industry" element={<ProtectedRoute element={<IndustryDataPage />} page='IndustryDataPage' />} />
            <Route path="/subreddits" element={<ProtectedRoute element={<SubredditPage />} page='SubredditPage' />} />
            <Route path="/subreddits-dev" element={<ProtectedRoute element={<SubredditPageDev />} page='SubredditPageDev' />} />
            <Route path="/gmaps-api" element={<ProtectedRoute element={<GMapsPage />} page='GMapsPage' />} />
            <Route path="/scriptsync" element={<ProtectedRoute element={<ScriptSyncPage />} page='ScriptSyncPage' />} />
            <Route path="/youtube-geo-search" element={<ProtectedRoute element={<YTGeoSearchPage />} page='YTGeoSearchPage' />} />
            <Route path="/youtube-transcript-search" element={<ProtectedRoute element={<YTTranscriptPage />} page='YTTranscriptPage' />} />
            <Route path="/youtube-transcript-search-dev" element={<ProtectedRoute element={<YTTranscriptPageDev />} page='YTTranscriptPageDev' />} />
            <Route path="/files" element={<ProtectedRoute element={<FilesPage />} page='FilesPage' />} />
            <Route path="/files-shared" element={<ProtectedRoute element={<SharedFilesPage />} page='SharedFilesPage' />} />
            <Route path="/admin/users" element={<ProtectedRoute element={<AdminUsersPage />} page='AdminUsersPage' />} />
            <Route path="/admin/roles" element={<ProtectedRoute element={<AdminRolesPage />} page='AdminRolesPage' />} />
            <Route path="/admin/activity" element={<ProtectedRoute element={<AdminActivityPage />} page='AdminActivityPage' />} />
            <Route path="/register/:inviteId" element={<RegisterPage setAuthenticated={setAuthenticated} />} />
            <Route path="/files/share/:uuid" element={<FileDownloadPage />} />
          </Routes>
        </Router>
      </ChakraProvider>
    </SaasProvider>
  );
};

export default App;
