import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Spacer,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
} from '@chakra-ui/react'
import {
  AppShell,
  Sidebar,
  SidebarSection,
  SidebarOverlay,
  NavItem,
  NavGroup,
  PersonaAvatar,
} from '@saas-ui/react'
import {
  FiHome,
  FiActivity,
  FiRss,
  FiMapPin,
  FiMap,
  FiBook,
  FiFileText,
  FiShare,
  FiUsers,
  FiUserPlus,
  FiSettings,
  FiAlignLeft,
  FiChevronsLeft,
  FiChevronsRight,
  FiPieChart,
} from 'react-icons/fi';
import { AiFillPieChart } from 'react-icons/ai';
import MasterLayout from './MasterLayout';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCurrentUser, logoutUser } from '../../actions/user';
import { selectCurrentUser } from '../../selectors/user';
import MUIDarkModeSwitch from './MUIDarkModeSwitch';
import { toggleSidebar as toggleSidebarAction, setSidebarState } from '../../actions/appmenu';
import { selectSidebarState } from '../../selectors/appmenu';
import { setUserToggled } from '../../actions/appmenu';
import { selectUserToggled } from '../../selectors/appmenu';

const MasterUI = ({ children, noPadding }) => {
  // Replace the local state with the Redux state for sidebar open/closed
  const isOpen = useSelector(selectSidebarState);
  const userToggled = useSelector(selectUserToggled);
  const currentUser = useSelector(selectCurrentUser);
  const userRoles = useSelector(state => state.user.roles) || [];
  const permissions = useSelector(state => state.user.permissions) || [];
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    dispatch(toggleSidebarAction());
    dispatch(setUserToggled(true));
  };

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logoutUser())
      .then(() => {
        window.location.reload();
      })
      .catch(err => console.error(err));
  }

  const isAdmin = userRoles.includes('Admin');

  const urlToPageName = {
    '/dashboard': 'DashboardPage',
    '/settings': 'SettingsPage',
    '/subreddits': 'SubredditPage',
    '/subreddits-dev': 'SubredditPageDev',
    '/gmaps-api': 'GMapsPage',
    '/youtube-geo-search': 'YTGeoSearchPage',
    '/youtube-transcript-search': 'YTTranscriptPage',
    '/files': 'FilesPage',
    '/files-shared': 'SharedFilesPage',
    '/admin/users': 'AdminUsersPage',
    '/admin/roles': 'AdminRolesPage',
    '/admin/activity': 'AdminActivityPage',
    '/scriptsync': 'ScriptSyncPage',
    '/industry': 'IndustryDataPage',
  };
  const isDisabled = (href) => !permissions.includes(urlToPageName[href]);

  return (
    <AppShell
      sidebar={
        <Sidebar
          style={{ height: '100vh' }}
          position="fixed"
          toggleBreakpoint={false}
          borderWidth="1"
          variant={isOpen ? 'default' : 'compact'}
          transition="width"
          transitionDuration="normal"
          width={isOpen ? '220px' : '14'}
          minWidth="auto"
          bg="white"
          _dark={{
            bg: 'whiteAlpha.200',
          }}
        >
          <SidebarSection direction={isOpen ? 'row' : 'column'}>
            <Menu>
              <MenuButton
                as={IconButton}
                icon={
                  <PersonaAvatar
                    presence="online"
                    size="xs"
                    src="/showcase-avatar.jpg"
                  />
                }
                variant="ghost"
                display={isOpen ? 'block' : 'none'}
              />
              <Box 
                ml="2" 
                display={isOpen ? 'flex' : 'none'} 
                alignItems="center" 
                fontSize="md"
                fontWeight="bold"
              >
                {currentUser}
              </Box>
              <MenuList>
                <MenuItem onClick={handleLogout}>Sign out</MenuItem>
              </MenuList>
            </Menu>
            <Spacer />
            <MUIDarkModeSwitch ml={2} />
            <IconButton
              onClick={toggleSidebar}
              variant="ghost"
              size="sm"
              icon={isOpen ? <FiChevronsLeft /> : <FiChevronsRight />}
              aria-label="Toggle Sidebar"
            />
          </SidebarSection>

          <SidebarSection flex="1" overflowY="auto" aria-label="Main">
            <NavGroup>
              <Tooltip label="Home" placement="right" display={isOpen ? 'none' : 'block'}>
                <NavItem icon={<FiHome />} as={NavLink} to="/">Home</NavItem>
              </Tooltip>
              <Tooltip label="Dashboard" placement="right" display={isOpen ? 'none' : 'block'}>
                <NavItem icon={<FiPieChart />} as={NavLink} to="/dashboard">Dashboard</NavItem>
              </Tooltip>
              <Tooltip label="Settings" placement="right" display={isOpen ? 'none' : 'block'}>
                <NavItem icon={<FiSettings />} as={NavLink} to="/settings">Settings</NavItem>
              </Tooltip>
            </NavGroup>

            <NavGroup title="Industry" isCollapsible>
              <Tooltip label="Industry View (Beta)" placement="right" display={isOpen ? 'none' : 'block'}>
                <NavItem 
                  icon={<AiFillPieChart />} 
                  as={NavLink} 
                  to="/industry"
                  style={{ 
                    textDecoration: 'none', 
                    opacity: isDisabled("/industry") ? 0.5 : 1,
                    cursor: isDisabled("/industry") ? "default" : "pointer"
                  }}>
                  Industry View (Beta)
                </NavItem>
              </Tooltip>
            </NavGroup>

            <NavGroup title="Analytics" isCollapsible>
              <Tooltip label="Subreddits" placement="right" display={isOpen ? 'none' : 'block'}>
                <NavItem 
                  icon={<FiRss />} 
                  as={NavLink} 
                  to={isDisabled("/subreddits") ? null : "/subreddits"}
                  style={{ 
                    textDecoration: 'none', 
                    opacity: isDisabled("/subreddits") ? 0.5 : 1,
                    cursor: isDisabled("/subreddits") ? "default" : "pointer"
                  }}>
                  Subreddits
                </NavItem>
              </Tooltip>
            </NavGroup>

            <NavGroup title="Tools" isCollapsible>
              <Tooltip label="Gmaps API" placement="right" display={isOpen ? 'none' : 'block'}>
                <NavItem 
                  icon={<FiMapPin />} 
                  as={NavLink} 
                  to={isDisabled("/gmaps-api") ? null : "/gmaps-api"}
                  style={{ 
                    textDecoration: 'none', 
                    opacity: isDisabled("/gmaps-api") ? 0.5 : 1,
                    cursor: isDisabled("/gmaps-api") ? "default" : "pointer"
                  }}>
                  Gmaps API
                </NavItem>
              </Tooltip>
              <Tooltip label="YT Geo Search" placement="right" display={isOpen ? 'none' : 'block'}>
                <NavItem 
                  icon={<FiMap />} 
                  as={NavLink} 
                  to={isDisabled("/youtube-geo-search") ? null : "/youtube-geo-search"}
                  style={{ 
                    textDecoration: 'none', 
                    opacity: isDisabled("/youtube-geo-search") ? 0.5 : 1,
                    cursor: isDisabled("/youtube-geo-search") ? "default" : "pointer"
                  }}>
                  YT Geo Search
                </NavItem>
              </Tooltip>
              <Tooltip label="YT Transcript Search" placement="right" display={isOpen ? 'none' : 'block'}>
                <NavItem 
                  icon={<FiBook />} 
                  as={NavLink} 
                  to={isDisabled("/youtube-transcript-search") ? null : "/youtube-transcript-search"}
                  style={{ 
                    textDecoration: 'none', 
                    opacity: isDisabled("/youtube-transcript-search") ? 0.5 : 1,
                    cursor: isDisabled("/youtube-transcript-search") ? "default" : "pointer"
                  }}>
                  YT Transcript Search
                </NavItem>
              </Tooltip>
              <Tooltip label="ScriptSync" placement="right" display={isOpen ? 'none' : 'block'}>
                <NavItem 
                  icon={<FiAlignLeft />} 
                  as={NavLink} 
                  to={isDisabled("/scriptsync") ? null : "/scriptsync"}
                  style={{ 
                    textDecoration: 'none', 
                    opacity: isDisabled("/scriptsync") ? 0.5 : 1,
                    cursor: isDisabled("/scriptsync") ? "default" : "pointer"
                  }}>
                  ScriptSync
                </NavItem>
              </Tooltip>
            </NavGroup>

            <NavGroup title="File Share" isCollapsible>
              <Tooltip label="Files" placement="right" display={isOpen ? 'none' : 'block'}>
                <NavItem 
                  icon={<FiFileText />} 
                  as={NavLink} 
                  to={isDisabled("/files") ? null : "/files"}
                  style={{ 
                    textDecoration: 'none', 
                    opacity: isDisabled("/files") ? 0.5 : 1,
                    cursor: isDisabled("/files") ? "default" : "pointer"
                  }}>
                  Files
                </NavItem>
              </Tooltip>
              <Tooltip label="Shared" placement="right" display={isOpen ? 'none' : 'block'}>
                <NavItem 
                  icon={<FiShare />} 
                  as={NavLink} 
                  to={isDisabled("/files-shared") ? null : "/files-shared"}
                  style={{ 
                    textDecoration: 'none', 
                    opacity: isDisabled("/files-shared") ? 0.5 : 1,
                    cursor: isDisabled("/files-shared") ? "default" : "pointer"
                  }}>
                  Shared
                </NavItem>
              </Tooltip>
            </NavGroup>

            {isAdmin && (
              <NavGroup title="Admin" isCollapsible>
                <Tooltip label="Users" placement="right" display={isOpen ? 'none' : 'block'}>
                  <NavItem 
                    icon={<FiUsers />} 
                    as={NavLink} 
                    to={isDisabled("/admin/users") ? null : "/admin/users"}
                    style={{ 
                      textDecoration: 'none', 
                      opacity: isDisabled("/admin/users") ? 0.5 : 1,
                      cursor: isDisabled("/admin/users") ? "default" : "pointer"
                    }}>
                    Users
                  </NavItem>
                </Tooltip>
                <Tooltip label="Roles" placement="right" display={isOpen ? 'none' : 'block'}>
                  <NavItem 
                    icon={<FiUserPlus />} 
                    as={NavLink} 
                    to={isDisabled("/admin/roles") ? null : "/admin/roles"}
                    style={{ 
                      textDecoration: 'none', 
                      opacity: isDisabled("/admin/roles") ? 0.5 : 1,
                      cursor: isDisabled("/admin/roles") ? "default" : "pointer"
                    }}>
                    Roles
                  </NavItem>
                </Tooltip>
                <Tooltip label="User Activity" placement="right" display={isOpen ? 'none' : 'block'}>
                  <NavItem 
                    icon={<FiActivity />} 
                    as={NavLink} 
                    to={isDisabled("/admin/activity") ? null : "/admin/activity"}
                    style={{ 
                      textDecoration: 'none', 
                      opacity: isDisabled("/admin/activity") ? 0.5 : 1,
                      cursor: isDisabled("/admin/activity") ? "default" : "pointer"
                    }}>
                    User Activity
                  </NavItem>
                </Tooltip>
              </NavGroup>
            )}
          </SidebarSection>
          <SidebarOverlay zIndex="1" />
        </Sidebar>
      }
    >
      <div>
        <MasterLayout noPadding={noPadding} sidebarWidth={isOpen ? '220px' : '14'}>
          {children}
        </MasterLayout>
      </div>
    </AppShell>
  )
}

export default MasterUI;
