import React, { useState } from 'react';
import { Box, Input, Divider, useColorModeValue } from '@chakra-ui/react';

const AppUISearchBar = ({ onSearch, endpoint, showDivider = true, lightModeBgColor, darkModeBgColor }) => {
  const [searchText, setSearchText] = useState('');
  const bgColor = useColorModeValue(lightModeBgColor, darkModeBgColor);

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      onSearch(endpoint, searchText);
    }
  };

  return (
    <Box bgColor={bgColor}>
      <Box p={4}>
        <Input
          size="sm"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={handleSearch}
        />
      </Box>
      {showDivider && <Divider />}
    </Box>
  );
};

export default AppUISearchBar;
